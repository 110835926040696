import * as React from "react";
import { Layout } from "../../components/layout";

const IndexPage = () => {
  return (
    <>
      <Layout title="Welpenwünsche">
        <main className="container">
          <h1>
            Welche Wünsche und Ansprüche stellt ein kleiner Welpe an seine neue
            Familie?
          </h1>

          <p className="pt-3">
            <strong>...gib mir Zeit mich bei Euch einzugewöhnen,</strong> alles
            ist neu und fremd, bitte lade keine Freunde gleich am ersten Tag
            ein, die mich "bewundern" möchten.
          </p>
          <p className="pt-3">
            <strong>
              ...lass mich in der ersten Zeit und in den ersten Nächten in
              meinem neuen Zuhause nicht alleine,
            </strong>{" "}
            ich brauche Deine Nähe, denn ich vermisse meine Mama und Geschwister
            so sehr.
          </p>
          <p className="pt-3">
            <strong>
              ...bitte sag Deinen Kindern, dass ich nicht der Stoffteddy auf dem
              Bett bin,
            </strong>{" "}
            sondern es mir weh tut wenn (ungewollte)ungeschickte Kinderhände in
            meine Augen, Nase und Ohren bohren.
          </p>
          <p className="pt-3">
            <strong>
              ...weck mich bitte nicht auf wenn ich ein Nickerchen mache,
            </strong>{" "}
            ich brauche nämlich viel Schlaf.
          </p>
          <p className="pt-3">
            <strong>...reagiere mit Geduld und Verständnis</strong> wenn das
            kleine Pfützchen wieder einmal auf dem Teppich passiert ist. Ich bin
            doch noch sehr klein und vergess mich manchmal im Spiel.
          </p>
          <p className="pt-3">
            <strong>
              ...verlang nicht nur ernste Gehorsamsübungen von mir,
            </strong>{" "}
            sondern spiel und toll mit mir auch öfter herum.
          </p>
          <p className="pt-3">
            <strong>
              ...lass mich so oft es geht mit anderen Hunden spielen,
            </strong>{" "}
            ich brauche diesen Kontakt und Austausch mit meinen Artgenossen.
          </p>
          <p className="pt-3">
            <strong>
              ...Bitte lass mich so oft wie möglich von der Leine.
            </strong>{" "}
            Ich laufe dir wirklich nicht weg!
          </p>
          <p className="pt-3">
            <strong>...nimm mich überall mit hin,</strong> wo Du mich mitnehmen
            kannst und wo Du gerne hingehst, denn alleine bin ich nicht gerne.
          </p>
          <p className="pt-3">
            <strong>
              ...verwende immer die gleichen Worte für die gleichen Dinge,
            </strong>{" "}
            damit ich deine Sprache richtig verstehen lerne.
          </p>
          <p className="pt-3">
            <strong>...bekomme bitte nicht gleich einen Tobsuchtsanfall</strong>{" "}
            wenn Deine Hausschuhe mir einfach zu gut "geschmeckt" haben.
          </p>
          <p className="pt-3">
            <strong>...wenn Du mit mir schimpfen musst,</strong> dann bitte nur
            wenn ich es auch verstehen kann, d.h.wenn Du mich unmittelbar
            "ertappt" hast. Denn später weis ich es nicht mehr.
          </p>
          <p className="pt-3">
            <strong>
              ...und wenn ich dann bei Euch bin beginnt für uns alle eine neue
              und aufregende Zeit, ich freu mich auf Eure Liebe und Zuwendung,
              meine Züchterin höre ich immer wieder sagen:
            </strong>
          </p>

          <p className="pt-3">
            "MIT VIEL LIEBE KANN MAN WIRKLICH NICHTS FALSCH MACHEN!"
          </p>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
